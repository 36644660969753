<template>
  <div>
    <a-modal :visible="visible" width="600px" @cancel="closeModal" title="Müşteri Temsilcisine Bildirim Gönder">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-form-item label="Bildirim Başlık">
          <a-input v-model:value="formState.title"/>
        </a-form-item>

        <a-form-item label="Bildirim İçeriği">
          <a-input v-model:value="formState.content"/>
        </a-form-item>

        <a-form-item label="Bildirim Türü">
          <a-select v-model:value="formState.type" placeholder="lütfen bildirim türünü seçin..">
            <a-select-option value="success">Başarılı İşlem</a-select-option>
            <a-select-option value="info">Bilgi</a-select-option>
            <a-select-option value="warning">Uyarı</a-select-option>
            <a-select-option value="error">Hata</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button @click="closeModal">Kapat</a-button>
        <a-button type="primary" @click="onSubmit"
                  :disabled="!(formState.title.length > 0 && formState.content.length > 0 && typeof formState.type !== 'undefined')">
          Bildirimi Gönder
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent, reactive, toRaw} from 'vue';
import {sendNotification} from "@/helpers/sendNotification";

export default defineComponent({
  setup() {
    const formState = reactive({
      title: '',
      content: '',
      type: undefined
    });

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      formState,
    };

  },
  props: ['visible', 'customer'],
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
    },
    onSubmit() {
      if (this.customer.agentID !== undefined) sendNotification('user', 'user', this.customer.agentID, this.formState.type, this.formState.title, this.formState.content)
      else sendNotification('user', 'department', 'sales', this.formState.type, this.formState.title, this.formState.content)
    }
  }
});
</script>