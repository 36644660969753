<template>
  <div>
    <a-modal :visible="visible" :footer="null" width="1000px" style="top: 20px" @cancel="closeModal"
             title="Satın Alınan Aktif Servisler">

      <a-table :dataSource="tableData" :columns="columns">
        <template #date="{ text }">
          <span>{{ text ? unixToDate(text) : 'Muhasebenin İlgilenmesi Gerekiyor' }}</span>
        </template>

        <template #payment="{ text }">
          <a-tag :color="text ? 'green' : 'red'">{{ text ? 'ÖDENDİ' : 'ÖDEME BEKLİYOR' }}</a-tag>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import {unixToDate} from "@/helpers/unixToDate";

export default defineComponent({
  setup() {
    const columns = [
      {
        title: "Servis Adı",
        dataIndex: "name",
        key: 'name'
      },
      {
        title: "Ödeme Durumu",
        dataIndex: "payment",
        key: 'payment',
        slots: {
          customRender: "payment"
        }
      },
      {
        title: "Oluşturulma Tarihi",
        dataIndex: "createdAt",
        key: 'createdAt',
        slots: {
          customRender: "date"
        }
      },
      {
        title: "Bitiş Tarihi",
        dataIndex: "endTime",
        key: 'endTime',
        slots: {
          customRender: "date"
        }
      }
    ]

    return {
      columns, unixToDate
    }
  },
  data() {
    return {
      tableData: []
    }
  },
  props: ['visible', 'customer'],
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
    }
  },
  watch: {
    visible(status) {
      if (status) {
        this.customer.catalogs.forEach(v => {
          if (v.status === true)
            this.tableData.push(v)
        })
      } else {
        this.tableData = []
      }
    }
  }
});
</script>