<template>
  <div>
    <a-modal :visible="visible" :footer="null" width="1000px" style="top: 20px" @cancel="closeModal"
             title="Müşteri İşlem Geçmişi">
      <CustomerLogs :customer="customer"/>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent} from 'vue';

import CustomerLogs from "@/components/CustomerLogs";

export default defineComponent({
  components: {CustomerLogs},
  props: ['visible', 'customer'],
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
    }
  }
});
</script>