<template>
  <div>
    <a-modal :visible="visible" :footer="null" title="Müşteri İşlemleri" @cancel="closeModal">
      <a-row :gutter="[8,8]">
        <a-col :span="8">
          <a-card :hoverable="true" @click="sendMailModalVisible = true">
            <div class="flex-center">
              <MailOutlined class="btnIcon"/>
              <span>Müşteriye E-Posta Gönder</span>
            </div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card :hoverable="true" @click="customerLogsModalVisible = true">
            <div class="flex-center">
              <LineChartOutlined class="btnIcon"/>
              <span>İşlem Geçmişini Görüntüle</span>
            </div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card :hoverable="true" @click="sendNotificationAgentModalVisible = true">
            <div class="flex-center">
              <NotificationOutlined class="btnIcon"/>
              <span>Temsilciye Bildirim Gönder</span>
            </div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card :hoverable="true" @click="buyedActiveServicesModalVisible = true">
            <div class="flex-center">
              <CreditCardOutlined class="btnIcon"/>
              <span>Satın Alınan Aktif Servisler</span>
            </div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card :hoverable="true" @click="sendNotificationAccountsModalVisible = true">
            <div class="flex-center">
              <NotificationOutlined class="btnIcon"/>
              <span>Muhasebeye Bildirim Gönder</span>
            </div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card :hoverable="true" @click="userLogin">
            <div class="flex-center">
              <ThunderboltOutlined class="btnIcon"/>
              <span>Müşteri Paneline Giriş</span>
            </div>
          </a-card>
        </a-col>

      </a-row>
    </a-modal>
  </div>
  <CustomerLogsModal :customer="customer" :visible="customerLogsModalVisible"
                     @closeModal="customerLogsModalVisible = $event"/>
  <BuyedActiveServices :customer="customer" :visible="buyedActiveServicesModalVisible"
                       @closeModal="buyedActiveServicesModalVisible = $event"/>
  <CustomerSendMail :customer="customer" :visible="sendMailModalVisible" @closeModal="sendMailModalVisible = $event"/>
  <SendNotificationAgentModal :customer="customer" :visible="sendNotificationAgentModalVisible"
                              @closeModal="sendNotificationAgentModalVisible = $event"/>
  <SendNotificationAccountsModal :customer="customer" :visible="sendNotificationAccountsModalVisible"
                                 @closeModal="sendNotificationAccountsModalVisible = $event"/>
</template>
<script>
import {defineComponent, ref} from 'vue';
import {
  MessageOutlined,
  MailOutlined,
  LineChartOutlined,
  NotificationOutlined,
  CreditCardOutlined,
  ThunderboltOutlined
} from '@ant-design/icons-vue'

/* Modals */
import CustomerLogsModal from "@/views/Support/components/Modals/CustomerLogsModal";
import BuyedActiveServices from "@/views/Support/components/Modals/BuyedActiveServices";
import CustomerSendMail from "@/views/Support/components/Modals/CustomerSendMail";
import SendNotificationAgentModal from "@/views/Support/components/Modals/SendNotificationAgentModal";
import SendNotificationAccountsModal from "@/views/Support/components/Modals/SendNotificationAccountsModal";
import {message} from "ant-design-vue";

import instance from "@/http/axios";

// https://my.connectprof.com/users/login/token/{token}
// yukarıdaki linkin devamına /tr/ayarlar_vb_vb şeklinde eklenerek direkt olarak yönlendirme yapılabilir.

export default defineComponent({
  props: ['visible', 'customer'],
  components: {
    /* icons */
    MessageOutlined, MailOutlined, LineChartOutlined, NotificationOutlined, CreditCardOutlined, ThunderboltOutlined,
    CustomerLogsModal, BuyedActiveServices, CustomerSendMail, SendNotificationAgentModal, SendNotificationAccountsModal
  },
  data() {
    return {
      customerLogsModalVisible: false,
      buyedActiveServicesModalVisible: false,
      sendMailModalVisible: false,
      sendNotificationAgentModalVisible: false,
      sendNotificationAccountsModalVisible: false
    }
  },
  methods: {
    userLogin() {
      let formData = new FormData();
      formData.append('cp_id', this.customer.cp_id)
      instance.post("/customer/access/cp_panel", formData).then(response => {
        if (response.data.success) {
          message.success('Müşteri paneline giriş başarılı! Birazdan yönlendiriliyorsunuz!')
          let redirect = window.open("", '_blank')
          redirect.document.write('<h1>NOC Tarafından ConnectProf\'a Yönlendiriliyorsunuz...</h1>')
          setTimeout(function () {
            redirect.document.write('<h2>İyi uçuşlar...</h2>')
            redirect.window.location.href = 'https://my.connectprof.com/users/login/token/' + response.data.data[0].token
          },1000)
        } else {
          message.error('Müşteri paneline giriş yapılırken bir sorun oluştu. Lütfen tekrar dene', 3)
        }
      })
    },
    closeModal() {
      this.$emit('closeModal', false)
    }
  }
});
</script>

<style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btnIcon {
  font-size: 38px;
}
</style>