<template>
  <div>
    <a-modal :visible="visible" width="1000px" style="top: 20px" @cancel="closeModal" title="Müşteriye Mail Gönder">

      <div v-if="loadedData" class="flex-row">
        <a-form-item label="Müşteri Maili">
          <a-select
              v-model:value="mailSelectvalue"
              show-search
              placeholder="Müşterinin mailini seçin."
              style="width: 250px"
          >
            <a-select-option v-for="mail in customerAllData.mail" :value="mail">{{ mail }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Gönderilecek Mail Türü">
          <a-select
              v-model:value="mailSelectTemplate"
              show-search
              placeholder="Mail türünü seçin."
              style="width: 250px"
          >
            <a-select-option value="default">Standart</a-select-option>
          </a-select>
          <a-button style="margin-left: 5px" v-if="!checkForm" @click="mailPreview">Önizle</a-button>
        </a-form-item>
        <a-form-item label="Mail Konusu">
          <a-input v-model:value="mailSubject"/>
        </a-form-item>
      </div>

      <div>
        <label class="mb-10">Mail İçeriği</label>
        <ckeditor class="mt-10" :editor="editor" v-model="editorData"></ckeditor>
      </div>

      <template #footer>
        <a-button type="default" @click="closeModal">Vazgeç</a-button>
        <a-button type="primary" :loading="loading" :disabled="checkForm" @click="sendMail">Maili Gönder</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import instance from "@/http/axios";
import {message} from "ant-design-vue";

export default defineComponent({
  data() {
    return {
      /* ckeditor */
      editor: ClassicEditor,
      editorData: "",
      mailSubject: "",

      /* button loading */
      loading: false,

      /* customer all data */
      customerAllData: null,

      /* select val */
      mailSelectvalue: null,
      mailSelectTemplate: null,
      loadedData: false,
    }
  },
  props: ['visible', 'customer'],
  computed: {
    checkForm() {
      return !(this.editorData !== "" && this.mailSelectvalue !== null && this.mailSelectTemplate !== null)
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
    },
    mailPreview() {
      let formData = new FormData();

      formData.append('customerName', this.customer.name)
      formData.append('subject', this.mailSubject)
      formData.append('template', this.mailSelectTemplate)
      formData.append('content', this.editorData)

      instance.post('/mail/preview', formData).then(response => {
        let preview = window.open("","_blank","width=685,height=800")
            preview.document.write(response.data)
            preview.focus();
      })

    },
    sendMail() {
      this.loading = true
      let formData = new FormData();

      formData.append('cOID', this.customer._id["$oid"])
      formData.append('cMAIL', this.mailSelectvalue)
      formData.append('cNAME', this.customer.name)
      formData.append('mailSubject', this.mailSubject)
      formData.append('selectMailTemplate', this.mailSelectTemplate)
      formData.append('sendMailContent', this.editorData)

      instance.post('/mail/sendCustomer', formData).then(response => {
        if (response.data.code === 200) {
          message.success(response.data.msg)
          this.loading = false
        }
      }).catch(error => {
        message.error('Mail gönderilirken bir sorun oluştu!')
        this.loading = false
      })
    }
  },
  watch: {
    visible(status) {
      if (status) {
        instance.get('/customer/get/only', {params: {oid: this.customer._id["$oid"]}}).then(response => {
          this.customerAllData = response.data
          this.loadedData = true;
        })
      } else {
        this.mailSelectvalue = null
        this.mailSelectTemplate = null
        this.editorData = ""
        this.mailSubject = ""
      }
    }
  }
});
</script>

<style scoped>
.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>