<template>
  <a-dropdown :trigger="['contextmenu']">
    <div class="ant-table-content">
      <a-table
          :columns="columns"
          :row-key="record => record.cp_id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
        <template #inputSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
          <div style="padding: 8px">
            <a-input
                :ref="c => (nameSearchTextInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              Ara
            </a-button>
            <a-button
                size="small"
                style="width: 90px"
                @click="handleReset(clearFilters)"
            >
              Sıfırla
            </a-button>
          </div>
        </template>
        <template #filterIcon="filtered">
          <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
        </template>

        <template #agents="{ text }">
      <span>
        <a-tag :color="typeof text !== 'undefined' ? text === false ? 'transparent' : 'green' : 'red'">
          {{ text === false ? '' : text ?? "ATANMAMIŞ" }}
        </a-tag>
      </span>
        </template>

        <template #name="{ text }"> {{ text }}</template>
        <template #business="{ text }"> {{ text }}</template>
        <template #email="{ email }"> {{ email }}</template>
        <template #phone="{ text: phone }"> {{ phone }}</template>

        <template #action="{ record }">
                <a-button type="primary" @click="customerProcess(record)">İşlemler</a-button>
                <a-button style="margin-left: 5px" type="primary" :loading="cploading" @click="userLogin(record.cp_id)">CP Panele Git</a-button>
        </template>

        <template #expandedRowRender="{ record }">

          <a-table @change="handleChange" :columns="serviceColumns" :row-key="record.key" :data-source="record.catalogs"
                   :pagination="true">

            <template #price="{ record }">
              <span>{{ record.discountPrice ?? record.price }}₺</span>
            </template>

            <template #status="{ text }">
          <span v-if="text">
            <a-badge status="success"/>
            Aktif
          </span>
              <span v-else>
            <a-badge status="error"/>
            Pasif
          </span>
            </template>

            <template #payment="{ text }">
          <span v-if="text">
            <a-badge status="success"/>
            Ödeme Yapıldı
          </span>
              <span v-else>
            <a-badge status="error"/>
            Ödeme Bekleniyor
          </span>
            </template>

            <template #options="{text}">
              <span>{{ text === "yearly" ? 'Yıllık' : 'Aylık' }}</span>
            </template>

            <template #refreshTime="{ text }">
              <span>{{ updateLoopText[text] }}</span>
            </template>

            <template #refreshType="{ text }">
              <span>{{ text === "1" ? 'Otomatik' : 'Manuel' }}</span>
            </template>

            <template #endTime="{ text }">
              <span>{{ text !== null ? unixToDate(text) : 'Belirlenmemiş' }}</span>
            </template>

          </a-table>

        </template>
      </a-table>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item key="1" @click="noFilterService">
          Tüm Müşteriler
        </a-menu-item>
        <a-menu-item key="2" @click="passiveService">
          Pasif Servislere Sahip Müşteriler
        </a-menu-item>
        <a-menu-item key="3" @click="paymentWaiting">
          Servis Ödemesi Bekleyen Müşteriler
        </a-menu-item>
        <a-menu-item key="4" @click="undefinedServiceEndTime">
          Bitiş Tarihi Belirlenmemiş Servisler
        </a-menu-item>
        <a-menu-item key="5">
          <a-popover v-model:visible="popoverVisible" title="X Süresi Kalan Müşteriler" trigger="click">
            <template #content>
              <a-input-search
                  type="number"
                  v-model:value="xTimeCustomer"
                  placeholder="varsayılan 15"
                  enter-button
                  @search="onSearch"
                  min="0"
                  step="1"
              />
            </template>
            X Süresi Kalan Müşteriler
          </a-popover>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <ActionsModal :visible="actionsModalVisible" @closeModal="this.actionsModalVisible = $event" :customer="actionsModalCustomerInfo" />
</template>
<script>
import {ref, computed, defineComponent, watch} from "vue"
import instance from "@/http/axios";
import {SearchOutlined, EditOutlined} from "@ant-design/icons-vue";
import {unixToDate} from "@/helpers/unixToDate";
import {message} from "ant-design-vue"

import ActionsModal from "@/views/Support/components/ActionsModal";

const columns = [
  {
    title: "Temsilci",
    dataIndex: "agents",
    width: '20%',
    slots: {customRender: "agents"}
  },
  {
    title: "Müşteri Adı",
    dataIndex: "name",
    sorter: true,
    slots: {
      customRender: "name",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Ticari Ünvan",
    dataIndex: "business",
    sorter: true,
    slots: {
      customRender: "business",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Müşteri Email",
    dataIndex: "mail",
    sorter: true,
    slots: {
      customRender: "mail",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Müşteri Telefon",
    dataIndex: "phone",
    slots: {
      customRender: "phone",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "İşlemler",
    slots: {customRender: "action"}
  }
];

export default defineComponent({
  setup() {
    const filteredInfo = ref();
    const sortedInfo = ref();
    /* service data */
    const serviceColumns = computed(() => {
      const filtered = filteredInfo.value || {};
      const sorted = sortedInfo.value || {};
      return [
        {
          title: 'Servis Adı',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Fiyat',
          dataIndex: 'price',
          key: 'price',
          slots: {
            customRender: "price"
          },
          sorter: (a, b) => a.price - b.price,
          sortOrder: sorted.columnKey === 'price' && sorted.order,
        },
        {
          title: 'Durumu',
          dataIndex: 'status',
          key: 'status',
          slots: {
            customRender: 'status',
          },
          filters: [
            {
              text: 'Aktif',
              value: true
            },
            {
              text: 'Pasif',
              value: false
            }
          ],
          // filterMultiple: false,
          onFilter: (value, record) => value === record.status,
        },
        {
          title: 'Ödeme Durumu',
          dataIndex: 'payment',
          key: 'payment',
          slots: {
            customRender: 'payment'
          },
          filters: [
            {
              text: 'Ödeme Yapıldı',
              value: true
            },
            {
              text: 'Ödeme Bekleniyor',
              value: false
            }
          ],
          // filterMultiple: false,
          onFilter: (value, record) => value === record.payment,
        },
        {
          title: 'Ödeme Döngüsü',
          dataIndex: 'options.paymentLoop',
          key: 'options.paymentLoop',
          slots: {
            customRender: 'options',
          },
          filters: [
            {
              text: 'Aylık',
              value: "monthly"
            },
            {
              text: 'Yıllık',
              value: "yearly"
            }
          ],
          // filterMultiple: false,
          onFilter: (value, record) => value === record.options.paymentLoop,
        },
        {
          title: 'Yenilenme Süresi',
          dataIndex: 'options.updateLoop',
          key: 'options.updateLoop',
          slots: {
            customRender: 'refreshTime',
          },
          filters: [
            {
              text: '4 Saat',
              value: "4hours"
            },
            {
              text: '2 Saat',
              value: "2hours"
            },
            {
              text: '1 Saat',
              value: "1hours"
            },
            {
              text: '30 Dakika',
              value: "30minutes"
            },
            {
              text: '15 Dakika',
              value: "15minutes"
            },
          ],
          // filterMultiple: false,
          onFilter: (value, record) => value === record.options.updateLoop,
        },
        {
          title: 'Yenilenme Şekli',
          dataIndex: 'options.updateType',
          key: 'options.updateType',
          slots: {
            customRender: 'refreshType',
          },
          filters: [
            {
              text: 'Otomatik',
              value: "1"
            },
            {
              text: 'Manuel',
              value: "0"
            }
          ],
          // filterMultiple: false,
          onFilter: (value, record) => value === record.options.updateType,
        },
        {
          title: 'Bitiş Tarihi',
          dataIndex: 'endTime',
          key: 'endTime',
          slots: {
            customRender: 'endTime',
          },
          sorter: (a, b) => a.endTime - b.endTime,
          sortOrder: sorted.columnKey === 'endTime' && sorted.order,
        }
      ]
    })

    /* service filter */
    const handleChange = (pagination, filters, sorter) => {
      filteredInfo.value = filters;
      sortedInfo.value = sorter;
    };
    const clearFilters = () => {
      filteredInfo.value = null;
    };
    const clearAll = () => {
      filteredInfo.value = null;
      sortedInfo.value = null;
    };

    /* row selection */
    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //   },
    //   onSelect: (record, selected, selectedRows) => {
    //     console.log(record, selected, selectedRows);
    //   },
    //   onSelectAll: (selected, selectedRows, changeRows) => {
    //     console.log(selected, selectedRows, changeRows);
    //   },
    // };

    /* popover input */
    const value = ref('');

    return {
      serviceColumns,
      handleChange,
      clearFilters,
      clearAll,
      // rowSelection,
      unixToDate,
      value
    }

  },
  components: {
    SearchOutlined, EditOutlined,
    ActionsModal
  },
  data() {
    return {
      fetchUrl: "/customer/get/all",
      data: [],
      params: {},
      visible: false,

      xTimeCustomer: 15,
      popoverVisible: false,

      detailModalVisible: false,
      detailModalData: null,

      actionsModalVisible: false,
      actionsModalCustomerInfo: null,

      nameSearchText: "",
      nameSearchTextInput: null,
      nameSearchedColumn: "",

      cploading: false,

      updateLoopText: {
        "4hours": "4 Saat",
        "2hours": "2 Saat",
        "1hours": "1 Saat",
        "30minutes": "30 Dakika",
        "15minutes": "15 Dakika",
      },

      pagination: {},
      loading: false,
      columns
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    customerProcess(customer){
      this.actionsModalVisible = true
      this.actionsModalCustomerInfo = customer
    },
    onSearch(searchValue) {
      if (searchValue === "") {
        message.error('Lütfen bir değer belirtin!')
      } else {
        let date = new Date();
        date.setDate(date.getDate() + Math.ceil(parseInt(searchValue)))
        this.fetchUrl = "/customer/get/xTimeLeft?time=" + date.getTime()
        this.fetch()
        this.popoverVisible = false
      }
    },
    noFilterService() {
      this.fetchUrl = "/customer/get/all"
      this.fetch()
    },
    passiveService() {
      this.fetchUrl = "/customer/get/havePassiveServices"
      this.fetch()
    },
    paymentWaiting() {
      this.fetchUrl = "/customer/get/servicePaymentWaiting"
      this.fetch()
    },
    undefinedServiceEndTime() {
      this.fetchUrl = "/customer/get/undefinedServiceEndTime"
      this.fetch()
    },
    serviceProcess(item) {
      this.detailModalVisible = true
      this.detailModalData = item
    },
    fetch(params = {}, searchColumn = null, searchColumnText = null) {
      this.loading = true;
      this.params = params;
      instance
          .get(this.fetchUrl, {
            params: {
              ...params,
              searchColumn,
              searchColumnText
            }
          })
          .then(response => {
            this.data = [];
            return response;
          })
          .then(response => {
            let totalPage = response.data.totalCustomerData;
            const pagination = {...this.pagination};
            pagination.total = totalPage;
            response.data.data.forEach(v => {

              let catalogsData = []
              if (typeof v.catalogs !== "undefined") {
                v.catalogs.forEach(catalog => {
                  catalog.services.forEach(service => {
                    catalogsData.push({
                      key: service.key,
                      cKey: catalog.key,
                      cOID: {_id: v._id},
                      name: service.name,
                      status: service.status,
                      options: service.options,
                      payment: service.payment,
                      price: service.discountPrice ?? service.price,
                      endTime: service.endTime ?? null,
                      createdAt: service.createdAt,
                      agents: false,
                    })
                  })
                })
              }

              this.data.push({
                _id: {
                  $oid: v._id["$oid"]
                },
                cp_id: v.cp_id,
                name: v.name,
                business: v.businessTitle,
                mail: v.mail[0],
                agents: typeof v.agent !== "undefined" ? v.agent.name : undefined,
                agentID: typeof v.agent !== "undefined" ? v.agent.id : undefined,
                phone: v.phones[0],
                logs: v.logs,
                catalogs: catalogsData
              });
            });
            this.pagination = pagination;
            this.loading = false;
          });
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();

      this.nameSearchText = selectedKeys[0];
      this.nameSearchedColumn = dataIndex;
      this.fetch(this.params, this.nameSearchedColumn, this.nameSearchText);
      this.$forceUpdate();
    },
    handleReset(clearFilters) {
      clearFilters();
      this.nameSearchText = "";
    },
    userLogin(cp_id) {
      let formData = new FormData();
      this.cploading = true;
      formData.append('cp_id', cp_id)
      instance.post("/customer/access/cp_panel", formData).then(response => {
        if (response.data.success) {
          message.success('Müşteri paneline giriş başarılı! Birazdan yönlendiriliyorsunuz!')
          this.cploading = false
          let redirect = window.open("", '_blank')
          redirect.document.write('<h1>NOC Tarafından ConnectProf\'a Yönlendiriliyorsunuz...</h1>')
          setTimeout(function () {
            redirect.document.write('<h2>İyi uçuşlar...</h2>')
            redirect.window.location.href = 'https://my.connectprof.com/users/login/token/' + response.data.data[0].token
            // / => _
            // = => -e-
            // ? => -q-
            // & => -a-
          },1000)
        } else {
          message.error('Müşteri paneline giriş yapılırken bir sorun oluştu. Lütfen tekrar dene', 3)
        }
      })
    },
  }
});
</script>