<template>
  <Default>
    <template v-slot:breadcrumb>
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>Anasayfa</a-breadcrumb-item>
        <a-breadcrumb-item>Destek</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <template v-slot:content>
      <div class="content">
        <div class="content-head">
          <h3>Tüm Müşteriler</h3>
        </div>
        <CustomerTable/>
      </div>
    </template>
    <template v-slot:mTitle>Müşteriler | Bill</template>
  </Default>
</template>

<script>
import Default from "@/components/_layouts/Default";
import CustomerTable from "@/views/Support/components/CustomerTable";

export default {
  name: "Home",
  components: {
    Default, CustomerTable
  }
}
</script>

<style scoped>
.content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>